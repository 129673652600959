export const AKAMAI_IMAGE_URL = 'https://adn-static1.nykaa.com/nykdesignstudio-images';
export const IMAGE_KIT_URL = 'https://images-static.nykaa.com';
export const DEFAULT_TITLE =
  'Shop Designer Clothes & Accessories Online from Top Indian Designers | Nykaa Fashion';

export const GLOBAL_BRAND_NAME = 'Nykaa Fashion';

export const BRAND_LOGO = 'https://asset.nykaafashion.com/design-assets/NF_logo.svg';
export const BRAND_LOGO_FL = 'https://asset.footlocker.co.in/footlocker-images/flLogo.svg';
export const LOADING_IMAGE_PLACEHOLDER =
  'https://images-static.nykaa.com/fashion-images/pub/media/plpPlaceholder.png';

export enum RENDER_MODE {
  MOBILE,
  DESKTOP,
}

export const SLIDE_DIRECTIONS = {
  PREV: 'PREV',
  NEXT: 'NEXT',
};

export const CAROUSEL_DIRECTION = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};
export const DEFAULT_CURRENCY = '₹';
export const DEFAULT_CURRENCY_CODE = 'INR';
export const DEFAULT_COUNTRY = 'IN';
export const DEFAULT_COUNTRY_NAME = 'India';

export const IMAGE_WIDTH_MOBILE = 412;
export const IMAGE_WIDTH_DESKTOP = 503;

export const STATIC_PRODUCT_CARD_WIDTH = 300;

export const STATIC_PRODUCT_CARD_WIDTH_PLP = 270;

export const EAGER_LOAD_PROD_CARDS = 6;

export const API_CACHE_TTL = Number(process.env.API_CACHE_TTL) * 60 * 1000;

export enum WEB_APP_TYPE {
  MWEB = 'MWEB',
  DESKTOP = 'DESKTOP',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export const VERTICAL_NAME = 'nykaafashion';
export const PUBLIC_DOMAIN = 'nykaafashion.com';

export const EAGER_IMAGE_LOADING_COUNT = 10;

export const IOS_APP_ID = '1439872423';

export const COACHMARK_IDS = {
  IMAGE_SLIDE: 'IMAGE_SLIDE',
  SIMILAR_ICON: 'SIMILAR_ICON',
};

// in the order of coachmark priority/ steps
export const PLP_COACHMARK_VARIANTS = [COACHMARK_IDS.SIMILAR_ICON, COACHMARK_IDS.IMAGE_SLIDE];

export enum LOGO_TYPE {
  MWEB = 'MWEB',
  DWEB = 'DWEB',
  FOOTER = 'FOOTER',
  COUPONS = 'COUPONS',
  OFFERS = 'OFFERS',
}

export enum TAB_INDEX {
  ACTION_VISIBILITY = -1,
  ACTION_INVISIBILITY = 0,
}

export const MAIN_CONTENT_ID = 'mainContent';

export const CHECKOUT_COOKIES = {
  MOBILE: 'F_C_R_M',
  DESKTOP: 'F_C_R_D',
  PCI: 'PCI',
};

export const UPS_PARAMS = {
  INSTALL_OFFER: 'install_offer',
  NF: 'nf',
};

export const GET_IT_FOR = 'Get it for';

export const WELCOME_PRICE_EXPERIMENT = "best-price-message";