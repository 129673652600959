//theme
import { theme as fashionTheme } from 'shared/styles/theme/fashionTheme';

//data
import data from 'shared/components/MyAccount/data';
import JSON_LD_ORGANIZATION from 'shared/constants/JSON_LD/organization';
import JSON_LD_SEARCH from 'shared/constants/JSON_LD/search';

export const buildConfig = {
  APP_NAME: 'Nykaa Fashion',
  LOGO: {
    MWEB: {
      HEIGHT: 32,
      WIDTH: 58,
      URL: 'https://images-static.nykaa.com/fashion-images/pub/media/nykaa-logo.svg',
    },
    DWEB: {
      HEIGHT: 24,
      WIDTH: 108,
      URL: 'https://images-static.nykaa.com/fashion-images/pub/media/logo-full.svg',
    },
    FOOTER: {
      HEIGHT: 24,
      WIDTH: 108,
      URL: 'https://images-static.nykaa.com/fashion-images/pub/media/footer-logo.svg',
    },
    COUPONS: {
      HEIGHT: 24,
      WIDTH: 40,
      URL: 'https://images-static.nykaa.com/fashion-images/pub/media/nykaa-logo.svg',
      BACKGROUNDURL:
        'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/Coupon_bg.svg',
    },

    OFFERS: {
      HEIGHT: 40,
      WIDTH: 40,
      URL: 'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/fashion-images/fashion_offer.svg',
      BACKGROUNDURL:
        'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/Coupon_bg.svg',
    },
    LOGO_ALT_TEXT: 'Nykaa Fashion',
  },
  FAVICON: 'https://images-static.nykaa.com/fashion-images/media/favicon96x96.ico',
  PWA_ICONS: {
    ICON_512:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/fashion-images/pwa-icon-512x512.png',
    ICON_192:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/fashion-images/pwa-icon-192x192.png',
    ICON_144:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/fashion-images/pwa-icon-144x144.png',
    ICON_32:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/fashion-images/pwa-icon-32x32.png',
    ICON_16:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/fashion-images/pwa-icon-16x16.png',
  },
  THEME: fashionTheme,
  ECOM_STRIP_ICONS: {
    FREE_DELIVERY:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/wysiwyg/Free_Delivery.png',
    COD_AVAILABLE:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/wysiwyg/COD.png',
    RETURN_AND_EXCHANGE:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/wysiwyg/Return.png',
    CART_TRUCK:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/truck_fire_fashion.svg',
  },
  MY_ACCOUNT_DATA: data,
  LOADER_CONFIG: {
    PAGE_LOADER: 'NFLoader',
    CHUNK_FALLBACK_CONFIG: 'NFChunkLoader',
  },
  CART_PAYMENT_ICONS: {
    GENUINE_PRODUCTS:
      'https://images-static.nykaa.com/fashion-images/pub/media/nf-genuine-products.svg',
    SECURE_PAYMENTS:
      'https://images-static.nykaa.com/fashion-images/pub/media/nf-secure-payments.svg',
    EASY_RETURNS: 'https://images-static.nykaa.com/fashion-images/pub/media/nf-easy-returns.svg',
  },
  API_OLD: {
    DOMAIN_PARAM: 'nykaaFashion',
  },
  API_REDIRECTION_PATH: 'gateway-api',
  API: {
    DOMAIN_PARAM: 'NYKAA_FASHION',
  },
  DN_STORE: 'nf',
  COLOR: {
    MEGA_MENU: '#FFF',
    MEGA_MENU_TEXT: '#192837',
    PROMOTIONAL_BANNER: '#111314',
    PROMOTIONAL_BANNER_TEXT: '#FFFFFFB8',
    CART_COUNT: {
      BORDER: 'none',
      TEXT: '#FFF',
      BACKGROUND: '#FD7685',
    },
    COUPON_CODE_BACKGROUND: '#e6f2ff',
  },
  MY_ACCOUNT_APP_DOWNLOAD: true,
  MEGA_MENU_VERSION: 'V2',
  ABOUT_FOOTER_TEXT: 'About Nykaa Fashion',
  GUEST_LOGIN_MESSAGE:
    'Becoming a Nykaa Fashion member comes with easy order tracking, rewards, offers and more.',
  LOGIN_TOAST_MESSAGE: {
    DWEB: 'You have successfully logged in! Enjoy the shopping at Nykaa Fashion',
    MWEB: 'Dive into your Nykaa profile, coupons, & more',
  },
  DEFAULT_META: {
    title: 'Nykaa Fashion – Online Shopping of Clothes & Accessories from Top Designers',
    description:
      'Nykaa Fashion – Online Shopping Site for Designer Clothes, Accessories & Lifestyle Products in India. Shop at Best Prices &amp; Attractive Offers from India’s best Fashion Website.',
    keywords: 'online shopping, online shopping sites, online shopping websites, fashion',
  },
  SHARE: {
    PUBLIC_DOMAIN: 'nykaafashion.com',
    TEXT: 'Check out this product I found on Nykaa Fashion:',
    CANONICAL_HOMEPAGE_URL: 'https://www.nykaafashion.com',
  },
  COPYRIGHT: `© ${new Date().getFullYear()} Nykaa Fashion Ltd. All Rights Reserved`,
  JSON_LD_ORG: JSON_LD_ORGANIZATION,
  JSON_LD_SEARCH: JSON_LD_SEARCH,
};
