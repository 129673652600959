// libs
import { produce } from 'immer';
import get from 'lodash/get';

// helpers
import { cacheCustomerData } from 'shared/helpers/userCache';

// constants
import { ActionTypes } from './actions';
import { ON_INTL_NETWORK } from 'shared/constants/headers';
import { WEB_APP_TYPE } from 'shared/constants';

// defs
import { AppState, Actions, IHeaderOptions, PromotionalBanner } from './types';

export let initialState: AppState;

const initialHeaderState: IHeaderOptions = {
  showDiscount: true,
  showHamburger: true,
  showBack: false,
  showLogo: true,
  showSearch: true,
  showCart: true,
  headerMerge: false,
  showBottomBorder: true,
  showLogoOnLeft: true,
  showWishlist: true,
  cartNudgeCount: 0,
  wishlistNudgeCount: 0,
};

const DEFAULT_DISCOUNT_HEADER: PromotionalBanner = {
  action_url: '',
  text: '',
};

interface Props {
  ssrHeaders?: Record<string, string>;
  cookies?: Record<string, string>;
  deviceType?: DeviceType;
}

const connectApp = ({
  ssrHeaders = {},
  cookies = {},
  deviceType = { webAppType: WEB_APP_TYPE.DESKTOP },
}: Props) => {
  initialState = Object.freeze<AppState>({
    statusCode: 200,
    headerOptions: {
      ...initialHeaderState,
    },
    headerDiscount: DEFAULT_DISCOUNT_HEADER,
    menu: {
      isFetching: true,
      isError: false,
      data: [],
    },
    user: {
      isFetching: true,
      isError: false,
      cartCount: 0,
      customerData: null,
      formKey: '',
      base_location: '',
      upsData: {
        orderCount: null,
      },
      upsError: '',
    },
    inProgress: false,
    showHeader: true,
    showFooter: true,
    showFooterDownloadAppSection: true,
    ssrHeaders,
    cookies,
    showLoginOption: false,
    searchTerm: '',
    baseCountry: get(ssrHeaders, 'x-base-country-code', ''),
    showSuccessLoginToast: false,
    isPostRequestAllowed: false,
    onIntlNetwork: String(get(ssrHeaders, ON_INTL_NETWORK, 'false')) === 'true',
    deviceType,
    isIncognitoMode: false,
  });

  return (state: AppState = initialState, action: Actions): AppState =>
    produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
        case ActionTypes.SET_HEADER_OFFER: {
          draft.headerDiscount = payload;
          return;
        }

        case ActionTypes.FETCH_HEADER_OFFER_FAILED: {
          draft.headerDiscount = DEFAULT_DISCOUNT_HEADER;
          return;
        }

        case ActionTypes.FETCH_MENU_PROGRESS: {
          draft.menu.isFetching = true;
          return;
        }

        case ActionTypes.FETCH_MENU_SUCCESS: {
          draft.menu.isFetching = false;
          draft.menu.data = payload;
          return;
        }

        case ActionTypes.FETCH_MENU_FAILED: {
          draft.menu.isFetching = false;
          draft.menu.isError = true;
          return;
        }

        case ActionTypes.FETCH_USER_PROGRESS: {
          draft.user.isFetching = true;
          draft.user.isError = false;
          return;
        }

        case ActionTypes.FETCH_USER_SUCCESS:
        case ActionTypes.UPDATE_CUSTOMER_DATA: {
          draft.user.isFetching = false;
          const { formKey, customerData, cartCount, base_location } = payload;
          draft.user.formKey = formKey;
          draft.user.customerData = { ...draft.user.customerData, ...customerData };
          draft.user.cartCount = cartCount;
          draft.user.base_location = base_location;
          draft.baseCountry = base_location;
          draft.isPostRequestAllowed = true;
          // cache customer data
          cacheCustomerData({ ...customerData });
          return;
        }

        case ActionTypes.UPDATE_CUSTOMER_DETAILS: {
          const { cartCount, showLoginToast, ...rest } = payload;
          const newCustomerData = { ...draft.user.customerData, ...rest };

          const userId = newCustomerData.id || newCustomerData.userId;

          if (userId) {
            newCustomerData.id = userId;
          }
          draft.user.customerData = newCustomerData;
          draft.user.cartCount = cartCount || draft.user.cartCount;
          draft.showSuccessLoginToast = showLoginToast;
          // cache customer data
          cacheCustomerData({ ...newCustomerData });
          return;
        }

        case ActionTypes.FETCH_USER_FAILED: {
          draft.user.isFetching = false;
          draft.user.isError = true;
          return;
        }

        case ActionTypes.INCREMENT_CART_COUNT:
        case ActionTypes.UPDATE_CART_COUNT: {
          draft.user.cartCount = payload;
          return;
        }

        case ActionTypes.SET_RESPONSE_CODE: {
          draft.statusCode = payload;
          return;
        }

        case ActionTypes.SET_HEADER_OPTIONS: {
          draft.headerOptions = {
            ...draft.headerOptions,
            ...payload,
          };
          return;
        }

        case ActionTypes.RESET_HEADER_OPTIONS: {
          draft.headerOptions = {
            ...initialHeaderState,
          };
          return;
        }

        case ActionTypes.SET_IN_PROGRESS: {
          draft.inProgress = payload;
          return;
        }

        case ActionTypes.SET_HEADER_VISIBILITY: {
          draft.showHeader = payload;
          return;
        }

        case ActionTypes.SET_FOOTER_VISIBILITY: {
          draft.showFooter = payload;
          return;
        }

        case ActionTypes.SET_FOOTER_DOWNLOADAPP_SECTION_VISIBILITY: {
          draft.showFooterDownloadAppSection = payload;
          return;
        }

        case ActionTypes.SET_WEB_APP_TYPE: {
          draft.deviceType.webAppType = payload;
          return;
        }

        case ActionTypes.SHOW_LOGIN_OPTIONS: {
          draft.showLoginOption = payload;
          return;
        }
        case ActionTypes.SET_APP_SEARCH_TERM: {
          draft.searchTerm = payload;
          return;
        }

        case ActionTypes.REDIRECT: {
          const { redirectURL, statusCode } = payload;
          draft.redirectURL = redirectURL;
          draft.statusCode = statusCode;
          return;
        }

        case ActionTypes.HIDE_LOGIN_INFO_TOAST: {
          draft.showSuccessLoginToast = false;
          return;
        }
        case ActionTypes.SHOW_CART_NUDGE: {
          draft.headerOptions.cartNudgeCount = payload;
          return;
        }

        case ActionTypes.SHOW_WISHLIST_NUDGE: {
          draft.headerOptions.wishlistNudgeCount = payload;
          return;
        }

        case ActionTypes.SET_INCOGNITO_MODE: {
          draft.isIncognitoMode = payload;
          return;
        }

        case ActionTypes.SET_UPS_ORDER_COUNT: {
          draft.user.upsData.orderCount = payload;
          return;
        }

        case ActionTypes.SET_UPS_ERROR: {
          draft.user.upsError = payload;
          return;
        }
      }
    });
};

export default connectApp;
