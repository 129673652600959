import { BRAND_LOGO_FL } from 'shared/constants';

export default JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'Footlocker',
  legalName: 'Footlocker',
  description:
    'Footlocker.co.in is an online fashion website that offers latest sneakers, footwear and apparel. Exclusive styles from Nike, adidas, New Balance and more.',
  url: 'https://www.footlocker.co.in/',
  logo: BRAND_LOGO_FL,
  address: {
    '@type': 'PostalAddress',
    streetAddress:
      '#A2, 4th Floor, Cnergy IT Park, Old Standard Mill Compound, Appasaheb Marathe Marg, Prabhadevi',
    addressLocality: 'Prabhadevi',
    addressRegion: 'Mumbai',
    postalCode: '400025',
    addressCountry: 'India',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer support',
    email: 'support@footlocker.co.in',
    hoursAvailable: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Monday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Tuesday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Wednesday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Thursday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Friday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Saturday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Sunday',
        opens: '10:00:00',
        closes: '19:00:00',
      },
    ],
  },
});
