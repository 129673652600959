// libs
import get from 'lodash/get';

// helper
import { log } from 'shared/helpers/newRelic';
import { TAG_IDENTIFIER } from 'shared/helpers/SentryLogger/constants';
import { ServiceabilityDataObj } from 'shared/types/cart';

// constants
const SERVICEABILITY = 'serviceability';

const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const HOURS_IN_DAY = 24;

export const transformDeliveryText = (
  data: ServiceabilityDataObj,
  productSKU: string,
  freeDeliveryAvailable?: boolean
) => {
  const deliveryDate = get(data, `${productSKU}.o2dTat`, '');
  const shippingDate = get(data, `${productSKU}.o2sTat`, '');

  const isServiceable = deliveryDate !== null || shippingDate !== null;

  //  Note:
  //  const serviceable = get(data, `${productSKU}.serviceable`);
  //  serviceable key in the API data is not reliable.
  // Thus checking the dates.
  if (!isServiceable) {
    log({
      errTitle: `Both delivery data and shipping date are null for ${productSKU}`,
      tags: {
        [TAG_IDENTIFIER.MODULE]: SERVICEABILITY,
      },
    });
    return null;
  }

  let someDate = new Date();

  let suffix = '';
  let text = '';
  let prefix = '';

  // remove null after data is updated in preprod
  if (!(deliveryDate === '' || deliveryDate === null)) {
    prefix = freeDeliveryAvailable ? 'Free' : '';
    text = 'Delivery by ';
    const deliveryDays = Number(Number(deliveryDate) / HOURS_IN_DAY);
    someDate.setDate(someDate.getDate() + deliveryDays);
    suffix = `${someDate.getDate()} ${month[someDate.getMonth()]}`;

    return `<b>${prefix}</b> ${text} <b>${suffix}</b>`;
  }
  if (!(shippingDate === '' || shippingDate === null)) {
    text = 'Usually ships in ';
    const shippingDays = Number(Number(shippingDate) / HOURS_IN_DAY);
    suffix = `${shippingDays} ${shippingDays < 2 ? 'day' : 'days'}`;
    return `<b>${prefix}</b> ${text} <b>${suffix}</b>`;
  }
};

export const transformDeliveryTextV1 = (
  data: ServiceabilityDataObj,
  productSKU: string,
  freeDeliveryAvailable?: boolean
) => {
  const deliveryDate = get(data, `${productSKU}.o2dTat`, '');
  const shippingDate = get(data, `${productSKU}.o2sTat`, '');

  const isServiceable = deliveryDate !== null || shippingDate !== null;

  //  Note:
  //  const serviceable = get(data, `${productSKU}.serviceable`);
  //  serviceable key in the API data is not reliable.
  // Thus checking the dates.
  if (!isServiceable) {
    log({
      errTitle: `Both delivery data and shipping date are null for ${productSKU}`,
      tags: {
        [TAG_IDENTIFIER.MODULE]: SERVICEABILITY,
      },
    });
    return null;
  }

  let someDate = new Date();

  let suffix = '';
  let text = '';
  let prefix = '';

  // remove null after data is updated in preprod
  if (!(deliveryDate === '' || deliveryDate === null)) {
    prefix = freeDeliveryAvailable ? 'Free' : '';
    text = 'Delivery by ';
    someDate.setDate(someDate.getDate() + deliveryDate);
    suffix = `${someDate.getDate()} ${month[someDate.getMonth()]}`;

    return `<b>${prefix}</b> ${text} <b>${suffix}</b>`;
  }
  if (!(shippingDate === '' || shippingDate === null)) {
    text = 'Usually ships in ';
    suffix = `${shippingDate} ${shippingDate < 2 ? 'day' : 'days'}`;
    return `<b>${prefix}</b> ${text} <b>${suffix}</b>`;
  }
};

export const transformDeliveryTextForCart = (
  leadTime: number | null,
  estimatedDeliveryTime: number
) => {
  let text = '';
  let suffix = '';

  if (estimatedDeliveryTime !== null || estimatedDeliveryTime != undefined) {
    const estimatedDate = new Date(estimatedDeliveryTime * 1000);
    const day = estimatedDate.getDate();
    const mon = month[estimatedDate.getMonth()];

    text = 'Delivery by ';
    return `${text} <b>${day} ${mon}</b>`;
  }

  if (leadTime !== null || leadTime != undefined) {
    // const estimatedDate = new Date(leadTime * 1000 * 60 * 60 * 24);
    // const today = new Date();

    // let daysInEpoch = estimatedDate - today;

    let days = Math.ceil(leadTime / 24);

    if (days === 0) {
      days = 1;
    }
    text = 'Usually ships in ';
    suffix = `${days} ${days < 2 ? 'day' : 'days'}`;
    return `${text} <b>${suffix}</b>`;
  }
  return null;
};
